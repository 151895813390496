import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import {
  GasStationActionTypes,
  SendGasStationDataAction,
  TradeMoveAssetSuccessAction,
} from 'src/app/angular-wallet-base/actions/gasStation.actions';
import {
  AuthSendTokensSuccessAction,
  SendTokenActionTypes,
  SendTokensSuccessAction,
} from 'src/app/angular-wallet-base/actions/sendToken.actions';
import { AUTH_DATA_KEY, AUTH_REASON, WALLET_STORAGE_KEY } from 'src/app/angular-wallet-base/constants';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { EnvironmentService } from 'src/app/angular-wallet-base/services/environment.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getAuthenticated } from 'src/app/angular-wallet-base/store/wallet';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';
import { decryptData, isEnterKey, successTxsModal } from 'src/app/angular-wallet-base/utils';
import { BiometricAuthService } from '../../angular-wallet-base/services/biometric-auth.service';
import { WalletConnectEventRequestSignAuthSuccessAction } from 'src/app/angular-wallet-base/actions/walletConnect.actions';
import {
VestingLtcStateInitRequestAction,
DepositVestingLtcStateAction,
ClaimVestingLtcStateAction,
StakeVestingLtcStateAction,
UnStakeVestingLtcStateAction
} from "src/app/angular-wallet-base/actions/vestingLTC.actions";
import { EvmNetworkService } from 'src/app/angular-wallet-base/services/evm-network.service';
import { OneInchService } from 'src/app/angular-wallet-base/services/one-inch.service';

@Component({
  selector: 'app-pin-modal',
  templateUrl: './pin-modal.component.html',
  styleUrls: ['./pin-modal.component.scss'],
})
export class PinModalComponent extends SubscriptionListenerComponent implements OnInit, AfterViewInit {
  @ViewChild('pinInput') pinInput;
  public showPinError = false;
  public pinErrorMessage: string = '';
  public pageTitle = '';
  public pageTitleHtml = false;
  public authenticateTitle = '';
  public bauthTextLineOne = '';
  public bauthTextLineTwo = '';
  public pin: string = '';
  public showLoader: boolean = false;
  public authReason;
  public authenticated: boolean;
  public isDesktopMode: boolean = false;
  public upgradeInProgress: boolean = false;
  private password: string;
  public passwordType: string = 'password';
  public passwordIcon: string = 'eye';
  public showBackButton: boolean = false;
  public showForgotPin: boolean = false;
  private bauthActive: boolean;
  private bauthType;
  private fallbackToPin: boolean;

  constructor(
    private navParams: NavParams,
    private translate: TranslateService,
    protected store: Store<AppState>,
    private storage: StorageService,
    private modalController: ModalController,
    private actions$: Actions,
    private appMode: AppModeService,
    private bauth: BiometricAuthService,
    private environmentService: EnvironmentService,
    private evmNetworkService: EvmNetworkService,
    private oneInchService: OneInchService
  ) {
    super(store);
  }

  async ngOnInit() {
    this.authReason = await this.navParams.get('reason');
    Logger.info('auth reason:', this.authReason);

    if (this.authReason) {
      this.showBackButton = true;
    } else {
      this.showForgotPin = true;
    }

    this.subscriptions.add(this.appMode.isDesktopMode.subscribe((mode) => (this.isDesktopMode = mode)));
    this.subscriptions.add(this.store.select(getAuthenticated).subscribe((authenticated) => (this.authenticated = authenticated)));
    this.bauthActive = await this.bauth.isActive();

    await this.setTitle();
    if (!this.shouldShowPin()) {
      let bauthPin;
      this.bauthType = await this.bauth.getAuthenticationMethod();
      this.authenticateTitle = 'login.bauth_title';
      this.bauthTextLineOne = await this.translate
        .get(this.bauthType === 'face' ? 'login.bauth_face_login_line_one' : 'login.bauth_login_line_one')
        .toPromise();
      this.bauthTextLineTwo = await this.translate
        .get(this.bauthType === 'face' ? 'login.bauth_face_login_line_two' : 'login.bauth_login_line_two')
        .toPromise();
      try {
        bauthPin = await this.bauth.getPin();
      } catch (err) {
        Logger.info('ERR', err);
        await this.setTitle();
        this.fallbackToPin = true;
        return;
      }
      this.handlePin(bauthPin);
    }
  }

  shouldShowPin() {
    return !this.bauthActive || (this.bauthActive && this.authReason === AUTH_REASON.DEACTIVATE_BIOMETRIC) || this.fallbackToPin;
  }

  ngAfterViewInit() {
    this.pin = '';
    this.showLoader = false;
  }

  async setTitle() {
    switch (this.authReason) {
      case AUTH_REASON.AUTH_SEND_TOKENS:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.send_tokens';
        break;
      case AUTH_REASON.WALLET_CONNECT_SIGN:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.send_tokens';
        break;
      case AUTH_REASON.VIRTUAL_DEBIT_CARD_CREATE:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.virtual_debit_card_create';
        break;

      case AUTH_REASON.GAS_STATION_SEND:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.gas_station_send';
        break;

      case AUTH_REASON.PLACE_TRADE_ORDER:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = this.environmentService.trade ? 'login.place_trade_order' : 'swap_review_order.place';
        break;

      case AUTH_REASON.UPDATE_PASSWORD:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'settings.update_password';
        break;

      case AUTH_REASON.CANCEL_TRADE_ORDER:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.cancel_trade_order';
        break;
      case AUTH_REASON.TRADE_MOVE_ASSET:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.gas_station_send';
        break;

      case AUTH_REASON.USER_ASSOCIATE_ADDRESS:
        this.pageTitle = 'login.label_login_pin';
        this.authenticateTitle = 'login.set_primary_address';
        break;

      default:
        this.translate.stream('login.label_login_pin').subscribe((value) => {
          this.pageTitle = value;
        });
        this.translate.stream('pages.enter_pin').subscribe((value) => {
          this.authenticateTitle = value;
        });
        this.pageTitleHtml = true;
        break;
    }
  }

  // handle pin for validating user log status
  async handlePin(pin) {
    this.pin = pin;
    this.showLoader = true;
    let authData;
    let vaultData;
    try {
      authData = await this.storage.get(AUTH_DATA_KEY);
      Logger.info('authData', authData);
      vaultData = await this.storage.get(WALLET_STORAGE_KEY);
      Logger.info('vaultData', vaultData);
    } catch (error) {
      Logger.error('Error collecting storage data: ', error);
    }
    // Check pin
    let validPass;
    try {
      if (vaultData) {
        validPass = await decryptData(this.pin, vaultData);
      }
      if (authData) {
        validPass = await decryptData(this.pin, authData);
      }
    } catch (error) {
      Logger.error('Auth: ', error);
     }

    // update jwt token
    if (validPass) {
      this.doNavigate();
    } else {
      this.pinErrorMessage = await this.translate.get('alerts.invalid_input.pin').toPromise();
      this.showLoader = false;
      this.showPinError = true;
    }
  }

  async showError(error) {
    this.pinErrorMessage = error;
    this.showPinError = true;
  }

  handlePinClear(event) {
    this.showPinError = false;
    this.pinErrorMessage = '';
  }

  async handlePasswordSubmit() {
    await this.handlePin(this.password);
  }

  handlePasswordInputChange(event) {
    if (event.target.name === 'password') {
      this.password = event.target.value;
    }
    if (this.showPinError) {
      this.showPinError = false;
    }
  }

  handleTogglePassword() {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    this.passwordIcon = this.passwordIcon === 'eye' ? 'eye-off' : 'eye';
  }

  private resetAuthState() {
    this.showLoader = false;
    this.pinInput.pin = '';
  }

  private async successTxsInfo() {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    await successTxsModal(this.isDesktopMode, sideMenuHidden, this.modalController, 'pinModal');
  }

  async doNavigate() {
    switch (this.authReason) {
    case AUTH_REASON.STAKE_LODE:
        const amount = await this.navParams.get('amount');
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
      break;
      case AUTH_REASON.AUTH_SEND_TOKENS:
        await this.modalController.dismiss({}, '', 'confirmModal');
        this.store.dispatch(new AuthSendTokensSuccessAction({ pin: this.pin }));
        this.subscriptions.add(
          this.actions$
            .pipe(ofType(SendTokenActionTypes.SEND_TOKENS_SUCCESS), takeUntil(this.destroyed$))
            .subscribe(async (action: SendTokensSuccessAction) => {
              if (action.payload.txid) {
                await this.modalController.dismiss({}, '', 'sendAssetModal');
                await this.successTxsInfo();
              }
            })
        );
        break;

      case AUTH_REASON.GAS_STATION_SEND:
        this.store.dispatch(new SendGasStationDataAction({ pin: this.pin }));
        this.showLoader = true;
        await this.modalController.dismiss(true);
        break;
      case AUTH_REASON.TRADE_MOVE_ASSET:
        this.store.dispatch(new SendGasStationDataAction({ pin: this.pin }));
        this.showLoader = true;
        this.subscriptions.add(
          this.actions$
            .pipe(ofType(GasStationActionTypes.TRADE_MOVE_ASSET_SUCCESS), takeUntil(this.destroyed$))
            .subscribe(async (action: TradeMoveAssetSuccessAction) => {
              if (action.payload.txid) {
                await this.modalController.dismiss({ txid: action.payload.txid }, '', 'pinModal');
              }
            })
        );
        break;

      case AUTH_REASON.BUY_SWAP:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        break;

      case AUTH_REASON.PLACE_TRADE_ORDER:
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        break;

      case AUTH_REASON.CANCEL_TRADE_ORDER:
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        break;

      case AUTH_REASON.UPDATE_PASSWORD:
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        break;
      case AUTH_REASON.USER_ASSOCIATE_ADDRESS:
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        break;
      case AUTH_REASON.WALLET_CONNECT_SIGN:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        this.store.dispatch(new WalletConnectEventRequestSignAuthSuccessAction({ pin: this.pin }));
        break;
      case AUTH_REASON.VESTING_CONNECT_SIGN:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        //let userWalletAddress = await this.evmNetworkService.getWalletEVM(this.pin);
        let userWalletAddress = await this.oneInchService.getWallet(this.pin);
        console.log('userWalletAddress', userWalletAddress)
        this.store.dispatch(new VestingLtcStateInitRequestAction(userWalletAddress.address));
        break;
     case AUTH_REASON.VESTING_DEPOSITE_SIGN:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        //let userWalletVest = await this.evmNetworkService.getWalletEVM(this.pin);
        let userWalletVest = await this.oneInchService.getWallet(this.pin);
        this.store.dispatch(new DepositVestingLtcStateAction(userWalletVest));
        break;
     case AUTH_REASON.VESTING_STAKE_SIGN:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        //let userWalletVest = await this.evmNetworkService.getWalletEVM(this.pin);
        let userWalletVestStake = await this.oneInchService.getWallet(this.pin);
        this.store.dispatch(new StakeVestingLtcStateAction(userWalletVestStake));
        break;
      case AUTH_REASON.VESTING_CLAIM_SIGN:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        //let userWallet = await this.evmNetworkService.getWalletEVM(this.pin);
        let userWallet = await this.oneInchService.getWallet(this.pin);
        this.store.dispatch(new ClaimVestingLtcStateAction(userWallet));
        break;
      case AUTH_REASON.VESTING_UNSTAKE_SIGN:
        this.showLoader = true;
        await this.modalController.dismiss({ pin: this.pin }, '', 'pinModal');
        //let userWalletVest = await this.evmNetworkService.getWalletEVM(this.pin);
        let userWalletVestUnstake = await this.oneInchService.getWallet(this.pin);
        this.store.dispatch(new UnStakeVestingLtcStateAction(userWalletVestUnstake));
        break;
      default:
        // TODO NOTE: default login NAVIGATION experience is handled by AddressQuerySuccess action in wallet effects to ensure
        //       a good user-data-loading experience. Consider refactoring to a redux saga in the future.
        //       DO NOT ADD NAVIGATION HERE!!!!
        // TODO 2: we need to add this pattern to wallet setup/recover too as it has the '0' balance flash issue
        break;
    }
  }

  onKeyUp(ev) {
    if (isEnterKey(ev)) {
      this.handlePasswordSubmit();
    }
  }

  onBackButton() {
    this.modalController.dismiss();
  }
}
