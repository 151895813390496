import { walletReducer } from './wallet.reducer';
import { setupReducer } from './setup.reducer';
import { userPreferencesReducer } from './userPreferences.reducer';
import { sendTokenReducer } from './sendToken.reducer';
import { connectionReducer } from './connection.reducer';
import { gasStationReducer } from './gasStation.reducer';
import { priceOracleReducer } from './priceOracle.reducer';
import { virtualDebitCardReducer } from './virtualDebitCard.reducer';
import { devicesListReducer } from './devicesList.reducer';
import { appSettingsReducer } from './appSettings.reducer';
import { bankAccountsReducer } from './bankAccounts.reducer';
import { accountSetupReducer } from './accountSetup.reducer';
import { graphDataReducer } from './graphData.reducer';
import { walletConnectReducer } from './walletConnect.reducer';
import { vestingLtcReducer } from './vestingLTC.reducer';
import { stakingLodeReducer } from './stakingLode.reducer';
import { AppState } from '../store/appState';
import { ActionReducerMap } from '@ngrx/store';
import { purchaseOrderReducer } from './purchaseOrder.reducer';

export const rootReducer: ActionReducerMap<AppState> = {
  wallet: walletReducer,
  setup: setupReducer,
  userPreferences: userPreferencesReducer,
  sendToken: sendTokenReducer,
  connection: connectionReducer,
  gasStation: gasStationReducer,
  priceOracle: priceOracleReducer,
  virtualDebitCard: virtualDebitCardReducer,
  devicesList: devicesListReducer,
  appSettings: appSettingsReducer,
  bankAccount: bankAccountsReducer,
  accountSetup: accountSetupReducer,
  vestingLTC: vestingLtcReducer,
  stakingLode: stakingLodeReducer,
  graphData: graphDataReducer,
  walletConnect: walletConnectReducer,
  purchaseOrder: purchaseOrderReducer,
};
