import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { WalletGuardService } from "./angular-wallet-base/services/walletguard.service";
import { SettingsGuardService } from "./angular-wallet-base/services/settingsguard.service";
import { AuthGuardService } from "./angular-wallet-base/services/auth-guard.service";
import { IsDeactivateService } from "./angular-wallet-base/services/is-deactivate.service";
import { NonAuthGuardService } from "./angular-wallet-base/services/non-auth-guard.service-";
import { NonWalletGuardService } from "./angular-wallet-base/services/non-wallet-guard.service";
import { environment } from "../environments/environment";

const routes: Routes = [
  {
    path: "vault-intro",
    loadChildren: () => import("./views/vault/vault.module").then((m) => m.VaultModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "prime",
    loadChildren: () => import("./views/prime/prime.module").then((m) => m.PrimeModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "trade",
    loadChildren: () => import("./views/trade/trade.module").then((m) => m.TradeModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "buy-assets",
    loadChildren: () => import("./views/buy-assets/buy-assets.module").then((m) => m.BuyAssetsModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "exchange",
    loadChildren: () => import("./views/buy-assets/buy-assets.module").then((m) => m.BuyAssetsModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/intro",
     loadChildren: () => import("./views/setup/intro/intro3/intro3.module").then((m) => m.Intro3PageModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/intro2",
    loadChildren: () => import("./views/setup/intro/intro2/intro2.module").then((m) => m.Intro2PageModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/intro3",
    loadChildren: () => import("./views/setup/intro/intro3/intro3.module").then((m) => m.Intro3PageModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/welcome-intro",
    loadChildren: () => import("./views/setup/wallet-intro/wallet-intro.module").then((m) => m.WalletIntroPageModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/register-setup-intro/:id",
    loadChildren: () =>
      import("./views/setup/register-setup-intro/register-setup-intro.module").then((m) => m.RegisterSetupIntroPageModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/setup-pin",
    loadChildren: () => import("./views/setup/setup-pin/setup-pin.module").then((m) => m.SetupPinModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/change-pin",
    loadChildren: () => import("./views/setup/setup-pin/setup-pin.module").then((m) => m.SetupPinModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/setup-pin/:type",
    loadChildren: () => import("./views/setup/setup-pin/setup-pin.module").then((m) => m.SetupPinModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/confirm-pin",
    loadChildren: () => import("./views/setup/confirm-pin/confirm-pin.module").then((m) => m.ConfirmPinModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/confirm-changepin/:type",
    loadChildren: () => import("./views/setup/confirm-pin/confirm-pin.module").then((m) => m.ConfirmPinModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/confirm-pin/:type",
    loadChildren: () => import("./views/setup/confirm-pin/confirm-pin.module").then((m) => m.ConfirmPinModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/open-vault",
    loadChildren: () => import("./views/setup/open-vault/open-vault.module").then((m) => m.OpenVaultPageModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/setup-recovery-phrase",
    loadChildren: () => import("./views/setup/setup-recovery-phrase/setup-recovery-phrase.module").then((m) => m.SetupRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/setup-recovery-phrase/:type",
    loadChildren: () => import("./views/setup/setup-recovery-phrase/setup-recovery-phrase.module").then((m) => m.SetupRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/enter-recovery-phrase",
    loadChildren: () => import("./views/setup/enter-recovery-phrase/enter-recovery-phrase.module").then((m) => m.EnterRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/enter-recovery-phrase/:type",
    loadChildren: () => import("./views/setup/enter-recovery-phrase/enter-recovery-phrase.module").then((m) => m.EnterRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/confirm-recovery-phrase",
    loadChildren: () =>
      import("./views/setup/confirm-recovery-phrase/confirm-recovery-phrase.module").then((m) => m.ConfirmRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/confirm-recovery-phrase/:type",
    loadChildren: () =>
      import("./views/setup/confirm-recovery-phrase/confirm-recovery-phrase.module").then((m) => m.ConfirmRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/success-recovery-phrase",
    loadChildren: () =>
      import("./views/setup/success-recovery-phrase/success-recovery-phrase.module").then((m) => m.SuccessRecoveryPhrasePageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "setup/show-recovery-phrase",
    loadChildren: () => import("./views/setup/show-recovery-phrase/show-recovery-phrase.module").then((m) => m.ShowRecoveryPhraseModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "wallet/settings",
    loadChildren: () => import("./views/settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "wallet/update-password",
    loadChildren: () => import("./views/update-password/update-password.module").then((m) => m.UpdatePasswordModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "account-setup",
    loadChildren: () => import("./views/account-setup/account-setup.module").then((m) => m.AccountSetupPageModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "wallet-intro",
    loadChildren: () => import("./views/setup/wallet-intro/wallet-intro.module").then((m) => m.WalletIntroPageModule),
    canActivate: [NonAuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "register-setup-intro",
    loadChildren: () =>
      import("./views/setup/register-setup-intro/register-setup-intro.module").then((m) => m.RegisterSetupIntroPageModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "home-page/first-time",
    loadChildren: () => import("./views/home-page/first-time/first-time.module").then((m) => m.FirstTimePageModule),
    canActivate: [NonWalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "home-page/established",
    loadChildren: () => import("./views/home-page/established/established.module").then((m) => m.EstablishedPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "edit-profile",
    loadChildren: () => import("./views/edit-profile/edit-profile.module").then((m) => m.EditProfilePageModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "stake-lode",
    loadChildren: () => import("./views/stake-lode/stake-lode.module").then((m) => m.StakeLodePageModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "vest-ltc",
    loadChildren: () => import("./views/vest-ltc/vest-ltc.module").then((m) => m.VestLtcPageModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "reserv3",
    loadChildren: () => import("./views/reserv3/reserv3.module").then((m) => m.Reserv3PageModule),
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "forgot-pin/info",
    loadChildren: () => import("./views/forgot-pin/forgot-pin-info/forgot-pin-info.module").then((m) => m.ForgotPinInfoPageModule),
  },
  {
    path: "forgot-pin/confirm",
    loadChildren: () => import("./views/forgot-pin/forgot-pin-confirm/forgot-pin-confirm.module").then((m) => m.ForgotPinConfirmPageModule),
  },
  {
    path: "trade/advanced-graph/:pair",
    loadChildren: () => import("./views/trade/advanced-graph/advanced-graph.module").then((m) => m.AdvancedGraphPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: 'wallet-connect',
    loadChildren: () => environment.features.walletConnect
    ? import('./views/wallet-connect/wallet-connect.module').then(m => m.WalletConnectModule)  : import("./views/home-page/established/established.module").then((m) => m.EstablishedPageModule),
    canActivate: [WalletGuardService],
    runGuardsAndResolvers: "always",
  },
  {
    path: "my-cards",
    loadChildren: () => import("./views/lode-debit-card/lode-debit-card-home/lode-debit-card-home.module").then((m) => m.LodeDebitCardHomeModule),
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
    TranslateModule
  ],
  providers: [IsDeactivateService],
  exports: [RouterModule,
    TranslateModule],
})
export class AppRoutingModule { }
