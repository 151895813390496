import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CRYPTO_LIST } from 'src/app/angular-wallet-base/constants';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { SelectPaymentModal } from 'src/app/views/select-payment-modal/select-payment-modal';
import { AuthGuardService } from 'src/app/angular-wallet-base/services/auth-guard.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';

@Component({
  selector: 'app-select-crypto-card',
  templateUrl: './select-crypto-card.component.html',
  styleUrls: ['./select-crypto-card.component.scss'],
})
export class SelectCryptoCardComponent implements OnInit {

  constructor(private modalController: ModalController,
    public appMode: AppModeService,
    private authGuardService: AuthGuardService,
    protected store: Store<AppState>,
    private nav: NavController,
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }
  public cryptoType : any;
  public selectType : any;
  public symbol: any; 
  public selectedToken?;
  public isDesktopMode: boolean;
  public items : any[] = [];  

  async ngOnInit() {
    this.items = CRYPTO_LIST;
    this.modalController.dismiss({}, '', 'select-payment-model');
  }

  onCancel() {
    this.modalController.dismiss({}, '', 'select-crypto-model');
  }
  async onBack() {
    this.modalController.dismiss();
    const cssClass = (this.isDesktopMode) ? 'select-asset-modal-desktop' : 'select-asset-modal';
    const modal = await this.modalController.create({
      component: SelectPaymentModal,
      componentProps: {
        selectedToken: this.selectedToken,
        symbol:this.symbol
      },
      id: 'select-payment-model',
      cssClass,
      backdropDismiss: false,
      initialBreakpoint: (this.isDesktopMode) ? undefined : 0.9,
      breakpoints: (this.isDesktopMode) ? undefined : [0, 0.6, 0.9, 1],
    });
    await modal.present();
  }


  async selectCryptoCard(item: any) {
     this.onCancel()
    this.authGuardService.setSelectedBuyData({form:this.selectType});
    this.nav.navigateForward(['/buy-assets', this.symbol] , {
          queryParams: {
            payWith: item.payWith,
          },
        });
  }

}
